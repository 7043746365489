<template>
  <div :style="style" class="profile-picture" :class="{ large: large }">
    {{ initial }}
  </div>
</template>

<script>
export default {
  props: {
    fontSize: {
      type: String,
      default: '24px'
    },
    color: {
      type: String,
      default: '#fafafa'
    },
    initial: {
      type: String,
      default: "",
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      background: "#D5A5BF",
      alphabet: [
        { initial: "a", color: "#D5A5BF" },
        { initial: "b", color: "#F8A31C" },
        { initial: "c", color: "#82287D" },
        { initial: "d", color: "#FFE900" },
        { initial: "e", color: "#8166C8" },
        { initial: "f", color: "#D5A5BF" },
        { initial: "g", color: "#F8A31C" },
        { initial: "h", color: "#82287D" },
        { initial: "i", color: "#FFE900" },
        { initial: "j", color: "#8166C8" },
        { initial: "k", color: "#D5A5BF" },
        { initial: "l", color: "#F8A31C" },
        { initial: "m", color: "#82287D" },
        { initial: "n", color: "#FFE900" },
        { initial: "o", color: "#8166C8" },
        { initial: "p", color: "#D5A5BF" },
        { initial: "q", color: "#F8A31C" },
        { initial: "r", color: "#82287D" },
        { initial: "s", color: "#FFE900" },
        { initial: "u", color: "#8166C8" },
        { initial: "v", color: "#D5A5BF" },
        { initial: "w", color: "#F8A31C" },
        { initial: "x", color: "#82287D" },
        { initial: "y", color: "#FFE900" },
        { initial: "z", color: "#8166C8" },
      ],
    };
  },
  computed: {
    style() {
      return "background-color: " + this.background + "; font-size: " + this.fontSize + "; color: " + this.color;
    },
  },
  mounted() {
    for (const x in this.alphabet) {
      if (this.alphabet[x].initial == this.initial.toLowerCase()) {
        this.background = this.alphabet[x].color;
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.profile-picture {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 20px;
  min-height: 20px;
  border-radius: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    font-size: 14px;
  }
  &.large {
    font-size: 100px;
    @media only screen and (max-width: 1024px) {
      font-size: 80px;
    }
  }
}
</style>
