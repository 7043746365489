<template>
  <!-- /22849909437/homepage-banner-suara-penentu/middle-horizontal-banner -->
  <div class="container">
    <div
      id="div-gpt-ad-1696924412908-0"
      style="min-width: 300px; min-height: 60px"
    ></div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      var responsiveAdSlot = googletag
        .defineSlot(
          "/22849909437homepage-banner-suara-penentu/middle-horizontal-banner",
          [
            [468, 60],
            [300, 250],
          ],
          "div-gpt-ad-1696924412908-0"
        )
        .addService(googletag.pubads());
      var mapping = googletag
        .sizeMapping()
        .addSize(
          [992, 0],
          [
            [970, 250],
            [970, 90],
            [728, 90],
            [468, 60],
            [1, 1],
          ]
        )
        .addSize(
          [728, 0],
          [
            [728, 90],
            [320, 480],
            [320, 100],
            [320, 50],
            [300, 600],
            [300, 250],
            [1, 1],
          ]
        )
        .addSize(
          [320, 0],
          [
            [320, 480],
            [320, 100],
            [320, 50],
            [300, 600],
            [300, 250],
            [1, 1],
          ]
        )
        .addSize(
          [0, 0],
          [
            [320, 50],
            [1, 1],
          ]
        )
        .build();
      responsiveAdSlot.defineSizeMapping(mapping);
      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
      googletag.enableServices();
      googletag.pubads().enableLazyLoad();
      googletag.display("div-gpt-ad-1696924412908-0");
    });
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: fit-content;
  padding: 0;
  #div-gpt-ad-1696924412908-0 {
    width: fit-content;
    margin: 0 auto;
    ::v-deep iframe {
      margin-bottom: 24px !important;
    }
  }
}
</style>
