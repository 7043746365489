<template>
  <a
    :href="$router.resolve(to).href"
    :target="target"
    :rel="target === '_blank' ? 'noopener noreferrer' : ''"
  >
    <slot></slot>
  </a>
</template>

<script>
export default {
  props: {
    to: {
      type: String | Object,
    },
    target: {
      type: String,
      default: "_self",
    },
  },
};
</script>
