<template>
  <div :style="style" class="img-user-chat">
    {{ initial }}
  </div>
</template>

<script>
  export default {
    props: {
      initial: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        background: "#D5A5BF",
        alphabet: [
          {initial: "a", color: "#D5A5BF"},
          {initial: "b", color: "#F8A31C"},
          {initial: "c", color: "#82287D"},
          {initial: "d", color: "#FFE900"},
          {initial: "e", color: "#8166C8"},
          {initial: "f", color: "#D5A5BF"},
          {initial: "g", color: "#F8A31C"},
          {initial: "h", color: "#82287D"},
          {initial: "i", color: "#FFE900"},
          {initial: "j", color: "#8166C8"},
          {initial: "k", color: "#D5A5BF"},
          {initial: "l", color: "#F8A31C"},
          {initial: "m", color: "#82287D"},
          {initial: "n", color: "#FFE900"},
          {initial: "o", color: "#8166C8"},
          {initial: "p", color: "#D5A5BF"},
          {initial: "q", color: "#F8A31C"},
          {initial: "r", color: "#82287D"},
          {initial: "s", color: "#FFE900"},
          {initial: "u", color: "#8166C8"},
          {initial: "v", color: "#D5A5BF"},
          {initial: "w", color: "#F8A31C"},
          {initial: "x", color: "#82287D"},
          {initial: "y", color: "#FFE900"},
          {initial: "z", color: "#8166C8"},
        ],
      };
    },
    computed: {
      style() {
        return "background-color: " + this.background;
      },
    },
    mounted() {
      for (const x in this.alphabet) {
        if (this.alphabet[x].initial === this.initial.toLowerCase()) {
          this.background = this.alphabet[x].color;
        }
      }
    },
  };
</script>

<style lang="scss" scoped>
  .img-user-chat {
    margin-right: 5px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    text-align: center;
    color: #FFFFFF;
    font-size: 10px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
