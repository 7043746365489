<template>
  <div class="container-quick-count">
    <div class="quick-count">
      <div class="header-wrapper">
        <nuxt-link to="/suarapenentu" class="suara-penentu">
          <img
            src="/storage/images/suara-penentu/logo.svg"
            class="logo-suara-penentu"
            alt="Suara Penentu"
          />
          <div class="live">
            <div class="live-badge">
              <span class="live-icon"></span>
              <span class="live-title">LIVE</span>
            </div>
            <span class="live-quick-count-title"
              >REKAPITULASI SUARA PEMILU 2024</span
            >
          </div>
        </nuxt-link>
        <div class="sponsor">
          <div class="title">Didukung oleh:</div>
          <div class="sponsor-wrapper">
            <img
              src="/storage/images/suara-penentu/sponsor/logo-AQUA.png"
              alt="AQUA"
            />
            <img
              src="/storage/images/suara-penentu/sponsor/logo-formula.png"
              alt="FORMULA"
            />
            <img
              src="/storage/images/suara-penentu/sponsor/logo-strepsils.png"
              alt="STREPSILS"
            />
            <img
              src="/storage/images/suara-penentu/sponsor/logo-tehgelas.png"
              alt="TEH GELAS"
            />
            <img
              src="/storage/images/suara-penentu/sponsor/logo-amarbank.png"
              alt="AMARBANK"
            />
            <img
              src="/storage/images/suara-penentu/sponsor/logo-kin.png"
              alt="KIN"
            />
          </div>
        </div>
      </div>
      <div class="recapitulation">
        <div class="candidates">
          <div class="candidate-wrapper w455" v-if="showKpu">
            <span class="title">Hasil Perhitungan Resmi KPU</span>
            <div class="card-wrapper">
              <div
                class="candidate-item"
                v-for="(
                  recapitulation, index
                ) in $store.state.suaraPenentu.recapitulations.filter(
                  (f) => f.provider === 'kpu'
                )"
                :key="`candidate-kpu-${index}`"
              >
                <div class="surveor">
                  <span
                    class="surveor-title"
                    v-if="recapitulation.provider === 'kpu'"
                  ></span>
                  <img
                    :src="recapitulation.logo"
                    class="surveor-logo"
                    :alt="recapitulation.label"
                  />
                </div>
                <div class="candidates-wrapper">
                  <div
                    class="candidate"
                    v-for="(candidate, indexItem) in recapitulation.items"
                    :key="`candidate-item-${indexItem}`"
                  >
                    <img
                      :src="candidate.picture"
                      class="candidate-item-picture"
                      :alt="candidate.name"
                    />
                    <span class="candidate-item-percentage"
                      >{{ candidate.percentage }}%</span
                    >
                  </div>
                </div>
                <span class="candidate-item-total"
                  >Sumber:
                  <a href="https://pemilu2024.kpu.go.id/" target="_blank"
                    >Sirekap KPU</a
                  >
                  (Data masuk {{ recapitulation.totalReceivedData }}%)`</span
                >
              </div>
            </div>
          </div>
          <div class="candidate-wrapper">
            <span class="title">Hasil Hitung Cepat Lembaga Survei</span>
            <div class="card-wrapper">
              <div
                class="candidate-item"
                v-for="(
                  recapitulation, index
                ) in $store.state.suaraPenentu.recapitulations.filter(
                  (f) => f.provider !== 'kpu'
                )"
                :key="`candidate-non-kpu-${index}`"
              >
                <div class="surveor">
                  <span
                    class="surveor-title"
                    v-if="recapitulation.provider === 'kpu'"
                  ></span>
                  <img
                    :src="recapitulation.logo"
                    class="surveor-logo"
                    :alt="recapitulation.label"
                  />
                </div>
                <div class="candidates-wrapper">
                  <div
                    class="candidate"
                    v-for="(candidate, indexItem) in recapitulation.items"
                    :key="`candidate-item-${indexItem}`"
                  >
                    <img
                      :src="candidate.picture"
                      class="candidate-item-picture"
                      :alt="candidate.name"
                    />
                    <span class="candidate-item-percentage"
                      >{{ candidate.percentage }}%</span
                    >
                  </div>
                </div>
                <span class="candidate-item-total">{{
                  recapitulation.totalReceivedData < 1
                    ? `Data tersedia pukul 15.00 WIB`
                    : `Data masuk ${recapitulation.totalReceivedData}%`
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="recapitulation-mobile"
        v-if="$store.state.suaraPenentu.recapitulations.length > 0"
      >
        <div class="candidate-mobile">
          <div
            class="recapitulation-mobile-item"
            v-for="(candidate, index) in $store.state.suaraPenentu
              .recapitulations[0].items"
            :key="`candidate-mobile-${index}`"
          >
            <img :src="candidate.picture" :alt="candidate.name" />
          </div>
        </div>

        <div class="candidate-percentage">
          <div class="candidate-percentage-wrapper">
            <span class="title">Hasil Perhitungan Resmi KPU</span>
            <div
              class="candidate-percentage-item"
              v-for="(
                candidate, index
              ) in $store.state.suaraPenentu.recapitulations.filter(
                (f) => f.provider === 'kpu'
              )"
              :key="`candidate-percentage-item-${index}`"
            >
              <div class="candidate-percentage-title">
                Sumber:
                <a href="https://pemilu2024.kpu.go.id/" target="_blank"
                  >Sirekap KPU</a
                >
                (Data Masuk {{ candidate.totalReceivedData }}%)
              </div>
              <div class="candidate-percentage-card">
                <div
                  class="candidate-percentage-card-value"
                  v-for="(candidateItem, candidateItemIndex) in candidate.items"
                  :key="`candidate-percentage-card-value-${candidateItemIndex}`"
                >
                  {{ candidateItem.percentage }}%
                </div>
              </div>
            </div>
          </div>
          <div class="candidate-percentage-wrapper">
            <span class="title">Hasil Hitung Cepat Lembaga Survei</span>
            <div
              class="candidate-percentage-item"
              v-for="(
                candidate, index
              ) in $store.state.suaraPenentu.recapitulations.filter(
                (f) => f.provider !== 'kpu'
              )"
              :key="`candidate-percentage-item-${index}`"
            >
              <div class="candidate-percentage-title">
                {{
                  candidate.totalReceivedData < 1
                    ? `${candidate.label} (Data tersedia pukul 15.00 WIB)`
                    : `${candidate.label} (Data Masuk ${candidate.totalReceivedData}%)`
                }}
              </div>
              <div class="candidate-percentage-card">
                <div
                  class="candidate-percentage-card-value"
                  v-for="(candidateItem, candidateItemIndex) in candidate.items"
                  :key="`candidate-percentage-card-value-${candidateItemIndex}`"
                >
                  {{ candidateItem.percentage }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-wrapper">
        <div class="more-wrapper">
          <div class="more-quick-count" v-if="showProvider">
            <div
              class="list-surveor"
              @slide-change="changeProvider"
              v-swiper:mySwiper1="swiperOptions"
            >
              <div class="swiper-wrapper">
                <img
                  :src="surveor.logo"
                  class="list-surveor-item swiper-slide"
                  v-for="(
                    surveor, index
                  ) in $store.state.suaraPenentu.recapitulations.filter(
                    (f) => f.provider !== 'kpu'
                  )"
                  :key="`surveor-${index}`"
                  alt=""
                />
              </div>
              <div class="prev-slide">
                <Icons name="arrow-left" />
              </div>
              <div class="next-slide">
                <Icons name="arrow-right" />
              </div>
            </div>
          </div>

          <template v-if="!$store.state.suaraPenentu.isLoading">
            <template v-if="showTable">
              <div
                class="result-table"
                v-if="$store.state.suaraPenentu.recapitulationsByCity"
              >
                <div class="result-table-candidate">
                  <div class="label-title">
                    DATA MASUK
                    {{
                      $store.state.suaraPenentu.recapitulationsByCity
                        .totalReceivedData
                    }}%
                  </div>
                  <img
                    v-for="(candidate, index) in $store.state.suaraPenentu
                      .recapitulationsByCity.candidate"
                    :key="`candidate-city-${index}`"
                    :src="candidate"
                    alt=""
                  />
                </div>
                <div
                  class="result-table-city"
                  ref="sliderCity"
                  v-swiper:mySwiper2="swiperOptionsCity"
                >
                  <div class="swiper-wrapper">
                    <div
                      class="city-item swiper-slide"
                      v-for="(value, index) in $store.state.suaraPenentu
                        .recapitulationsByCity.items"
                      :key="`city-${index}`"
                    >
                      <div class="city-wrapper">
                        <div class="city-title">
                          {{ value.city }} ({{ value.totalReceivedData }}%)
                        </div>
                        <div class="tooptip">
                          <span>i</span>
                        </div>
                        <div class="tooltip-wrapper">{{ value.city }}</div>
                      </div>
                      <div
                        class="value"
                        v-for="(
                          valuePercentage, indexPercentage
                        ) in value.percentage"
                        :key="`percentage-value-${indexPercentage}`"
                      >
                        {{ valuePercentage }}%
                      </div>
                    </div>
                  </div>
                  <div class="prev-slide-city">
                    <Icons name="arrow-left" />
                  </div>
                  <div class="next-slide-city">
                    <Icons name="arrow-right" />
                  </div>
                </div>
              </div>
            </template>

            <div class="parpol-wrapper" v-if="showParpol">
              <h2 class="title">
                Rekapitulasi Data Perolehan Total Suara Nasional
              </h2>
              <div class="list-parpol" v-swiper:mySwiper3="swiperOptionsParpol">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide parpol-card"
                    :style="`background: ${data.baseColor}`"
                    v-for="(data, index) in $store.state.suaraPenentu
                      .recapitulationsByParpol.items"
                    :key="`parpol-${index}`"
                  >
                    <img
                      class="parpol-image"
                      :src="`https://cdn.narasi.tv/qc/parpol/${data.parpol}.webp`"
                      :alt="data.label"
                    />
                    <span class="parpol-percentage"
                      >{{ data.percentage }}%</span
                    >
                  </div>
                </div>
                <div class="prev-slide-parpol">
                  <Icons name="arrow-left" />
                </div>
                <div class="next-slide-parpol">
                  <Icons name="arrow-right" />
                </div>
              </div>
              <!-- 14 Feb 2024 pukul 18.00 WIB -->
              <span class="info"
                >Sampel masuk
                {{
                  $store.state.suaraPenentu.recapitulationsByParpol
                    .totalReceivedData
                }}%; Terakhir diperbarui
                {{ $moment().format("DD MMM YYYY [pukul] HH:mm") }} WIB</span
              >
            </div>
          </template>
          <template v-else>
            <div class="loading-wrapper-provider">
              <span>Loading...</span>
            </div>
          </template>
        </div>
        <div class="devider" v-if="linkSelengkapnya">
          <nuxt-link :to="linkSelengkapnya" class="more"
            >Lihat selengkapnya
            <Icons name="arrow-right" color="#564480" />
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icons from "./Icons.vue";
export default {
  props: {
    intervalProvider: false,
    intervalZone: false,
    intervalParpol: false,
    showKpu: true,
    showTable: true,
    showProvider: true,
    showParpol: true,
    showParpol: false,
    linkSelengkapnya: null,
  },
  components: { Icons },
  data() {
    return {
      readMore: this.showMore,
      controlledSwiper: null,
      swiperOptions: {
        autoplay: this.intervalProvider,
        navigation: {
          prevEl: ".prev-slide",
          nextEl: ".next-slide",
        },
      },
      swiperOptionsCity: {
        autoplay: this.intervalZone,
        breakpoints: {
          320: {
            slidesPerGroup: 1.6,
            slidesPerView: 1.6,
            navigation: {
              prevEl: "",
              nextEl: "",
            },
          },
          1023: {
            slidesPerGroup: 5.4,
            slidesPerView: 5.4,
            navigation: {
              prevEl: ".prev-slide-city",
              nextEl: ".next-slide-city",
            },
          },
        },
      },
      swiperOptionsParpol: {
        autoplay: this.intervalParpol,
        breakpoints: {
          320: {
            slidesPerGroup: 4.4,
            slidesPerView: 4.4,
            spaceBetween: 16,
            navigation: {
              prevEl: "",
              nextEl: "",
            },
          },
          1023: {
            slidesPerGroup: 12.4,
            slidesPerView: 12.4,
            spaceBetween: 16,
            navigation: {
              prevEl: ".prev-slide-parpol",
              nextEl: ".next-slide-parpol",
            },
          },
        },
      },
    };
  },
  methods: {
    random() {
      const months = [1, 2, 3];
      return Math.floor(Math.random() * months.length);
    },
    changeProvider() {
      this.$store.dispatch(
        "suaraPenentu/getRecapitulationsByCity",
        this.$store.state.suaraPenentu.recapitulations[
          this.mySwiper1.activeIndex + 1
        ].provider
      );

      this.$store.dispatch(
        "suaraPenentu/getRecapitulationsByParpol",
        this.$store.state.suaraPenentu.recapitulations[
          this.mySwiper1.activeIndex + 1
        ].provider
      );
    },
    async initData() {
      this.$store.dispatch("suaraPenentu/getRecapitulations");
      this.$store.dispatch(
        "suaraPenentu/getRecapitulationsByCity",
        "indikator"
      );
      this.$store.dispatch(
        "suaraPenentu/getRecapitulationsByParpol",
        "indikator"
      );
    },
  },
  mounted() {
    this.initData();
  },
};
</script>

<style lang="scss" scoped>
.container-quick-count {
  background-color: #f3f2f6 !important;
  background: url(../static/storage/images/suara-penentu/bg-container_QC.png)
    no-repeat;
  background-position: top;
  border-radius: 4px;
  background-size: contain;

  @media only screen and (max-width: 1024px) {
    background: url(../static/storage/images/suara-penentu/quick-count-mobile.svg)
      no-repeat;
  }

  .loading-wrapper-provider {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 260px;

    span {
      font-size: 16px;
      font-weight: 600;
    }
  }

  .quick-count {
    width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;

    @media only screen and (max-width: 1024px) {
      margin: 0;
      padding: 0 12px;
    }

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 20px 16px;
      align-items: center;
      @media only screen and (max-width: 1024px) {
        flex-direction: column;
      }
      .sponsor {
        padding: 8px 8px 4px 8px;
        box-shadow: 0px 2px 4px 0px #0000001a;
        background: #ffffff;
        border-radius: 8px;
        .title {
          font-size: 7.5px;
          font-weight: 300;
        }
        .sponsor-wrapper {
          display: flex;
          img {
            padding: 0 4px;
            height: 57.19px;
            &:nth-child(1) {
              border-right: 1px solid #d9d9d9;
            }
            &:nth-child(6) {
              border-left: 1px solid #d9d9d9;
            }
          }
        }
      }
    }

    .fade-enter-active {
      transition: opacity 0.3s;
    }

    .fade-enter {
      opacity: 0;
    }

    .suara-penentu {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;

      @media only screen and (max-width: 1024px) {
        justify-content: space-between;
        margin: 20px 0;
      }

      .logo-suara-penentu {
        height: 100%;
        width: 100px;
        object-fit: contain;
      }

      .live {
        background: #4c3d71;
        border-radius: 4px;
        padding: 8px;
        width: 227px;
        height: 52px;
        display: flex;
        gap: 8px;
        align-items: center;
        font-family: "Varela Round", sans-serif;

        @media only screen and (max-width: 1024px) {
          height: 100%;
          padding: 4px 8px;
          width: 217px;
        }

        .live-badge {
          display: flex;
          padding: 4px 6px;
          align-items: center;
          gap: 4px;
          background-color: #fff;
          border-radius: 4px;

          .live-icon {
            width: 14px;
            height: 14px;
            border-radius: 100%;
            background: #db001e;
            line-height: 0;
            -webkit-animation: breathing 1s ease-out infinite normal;
            animation: breathing 1s ease-out infinite normal;

            @keyframes breathing {
              0% {
                -webkit-transform: scale(0.8);
                -ms-transform: scale(0.8);
                transform: scale(0.8);
              }

              25% {
                -webkit-transform: scale(1);
                -ms-transform: scale(1);
                transform: scale(1);
              }

              60% {
                -webkit-transform: scale(0.8);
                -ms-transform: scale(0.8);
                transform: scale(0.8);
              }

              100% {
                -webkit-transform: scale(0.8);
                -ms-transform: scale(0.8);
                transform: scale(0.8);
              }
            }
          }
        }

        .live-quick-count-title {
          color: #fff;
          font-weight: 500;

          @media only screen and (max-width: 1024px) {
            font-size: 12px;
          }
        }
      }
    }

    .recapitulation-mobile {
      display: none;

      @media only screen and (max-width: 1024px) {
        display: block;
        padding: 0 0;
      }

      .candidate-mobile {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 8px;
        width: 100%;

        @media only screen and (max-width: 1024px) {
          padding-bottom: 10px;
          border-bottom: 1px solid #d9d9d9;
        }

        .recapitulation-mobile-item {
          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .candidate-percentage {
        padding: 8px 0;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .candidate-percentage-wrapper {
          border-bottom: 1px solid #d9d9d9;
          padding-bottom: 8px;
          text-align: center;
          display: flex;
          flex-direction: column;
          gap: 8px;

          .title {
            font-weight: 500;
            font-size: 14px;
            color: #000;
          }
        }

        .candidate-percentage-item {
          display: flex;
          padding-top: 4px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 4px;
          background: #fff;
          box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);

          .candidate-percentage-title {
            font-size: 13px;
            color: #000;
          }

          .candidate-percentage-card {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 4px;
            width: 100%;

            .candidate-percentage-card-value {
              background-color: #4c3d71;
              display: flex;
              padding: 4px 16px;
              width: 100%;
              align-items: center;
              justify-content: center;
              color: #fff;
              font-weight: 700;
              font-size: 16px;
              border-radius: 4px;
            }
          }
        }
      }
    }

    .recapitulation {
      border-radius: 4px;
      // border: 0.75px solid #C9C9C9;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(2px);
      padding: 16px;

      @media only screen and (max-width: 1024px) {
        display: none;
      }

      .candidates {
        display: flex;
        justify-content: space-between;
        gap: 8px;

        .candidate-wrapper {
          border-radius: 8px;
          padding: 8px;
          border: 0.75px solid #c9c9c9;
          height: fit-content;
          display: flex;
          gap: 8px;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &.w455 {
            width: 455px;
          }

          .title {
            font-weight: 500;
            font-size: 16px;
            color: #000;
          }

          .card-wrapper {
            display: flex;
            gap: 8px;
          }
        }

        .candidate-item {
          background: #fff;
          border-radius: 6px;
          padding: 8px 6px;
          text-align: center;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

          .surveor {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            .surveor-title {
              color: #000;
              font-size: 10px;
              font-weight: 500;
            }

            .surveor-logo {
              height: 24px;
              width: auto;
            }
          }

          .candidates-wrapper {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 4px;

            .candidate {
              text-align: center;
              padding: 8px 6px;

              .candidate-item-picture {
                width: 100%;
                object-fit: contain;
                height: 55.5px;
              }

              .candidate-item-percentage {
                text-align: center;
                font-weight: 700;
                font-size: 16px;
                color: #564480;
              }
            }
          }

          .candidate-item-total {
            font-weight: 300;
            font-size: 10px;
            color: #000;
          }
        }
      }
    }

    .container-wrapper {
      padding: 0 16px;

      @media only screen and (max-width: 1024px) {
        padding: 0px;
      }
    }

    .devider {
      display: flex;
      flex-basis: 100%;
      align-items: center;
      color: #564480;
      margin: 8px 0;

      &::before,
      &::after {
        content: "";
        flex-grow: 1;
        background: #564480;
        height: 1px;
        margin: 0 8px;
      }

      .more {
        display: flex;
        padding: 8px 16px;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        border-radius: 16px;
        border: 0.75px solid #564480;
        background: #fff;
        font-size: 14px;
        color: #564480;

        svg {
          width: 16px;
          height: 16px;
        }
      }
    }

    .more-quick-count {
      max-width: 100%;
      width: 231.81px;
      margin: 0 auto;
      padding-bottom: 16px;

      @media only screen and (max-width: 1024px) {
        padding-bottom: 24px;
      }

      .list-surveor {
        height: 32px;
        position: relative;

        .list-surveor-item {
          height: 32px;
          width: 100%;
          object-fit: contain;
        }

        .prev-slide {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 30000;

          ::v-deep {
            svg {
              width: 32px;
              height: 32px;
            }
          }
        }

        .next-slide {
          z-index: 30000;
          position: absolute;
          right: 0;
          top: 0;

          ::v-deep {
            svg {
              width: 32px;
              height: 32px;
            }
          }
        }
      }
    }

    .result-table {
      display: flex;
      max-width: 100%;

      .result-table-candidate {
        border-right: 1px solid #d9d9d9;
        width: 140px;
        display: flex;
        flex-direction: column;
        padding-right: 8px;
        gap: 2px;

        .label-title {
          border-radius: 4px;
          background: #564480;
          font-size: 10px;
          font-weight: 700;
          padding: 4px 8px;
          font-style: italic;
          color: #fff;
          margin-bottom: 2px;

          @media only screen and (max-width: 1024px) {
            font-style: normal;
          }
        }
      }

      .result-table-city {
        margin-left: 8px;
        position: relative;

        .city-item {
          width: 160px;
          display: flex;
          flex-direction: column;
          padding-right: 8px;
          gap: 2px;
          position: relative;
          .tooltip-wrapper {
            display: none;
            position: absolute;
            padding: 8px;
            width: 75%;
            top: 0;
            color: #fff;
            background: #000;
            border-radius: 8px;
            z-index: 1000;
          }
          .city-wrapper {
            display: flex;
            justify-content: space-between;
            background: #564480;
            align-items: center;
            padding: 0 8px;
            .city-title {
              font-size: 10px;
              padding: 3px 8px;
              color: #fff;
              margin-bottom: 2px;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              pointer-events: none;
            }
            .tooptip {
              cursor: pointer;
              padding: 4px;
              background: #fff;
              font-size: 8px;
              width: 12px;
              height: 12px;
              border-radius: 100%;
              color: #000;
              display: flex;
              justify-content: center;
              align-items: center;
              &:hover ~ .tooltip-wrapper {
                display: block;
              }
            }
          }

          .value {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 31px;
            background: #d0d0d0;
            font-weight: 700;
            color: #0c0b0d;
          }
        }

        .prev-slide-city {
          position: absolute;
          top: 60%;
          width: 32px;
          height: 32px;
          background-color: #fff;
          // filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
          border-radius: 100%;
          left: 4px;
          transform: translateY(-50%);
          z-index: 1;

          ::v-deep {
            svg {
              width: 32px;
              height: 32px;
            }
          }

          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }

        .next-slide-city {
          z-index: 1;
          position: absolute;
          top: 60%;
          right: 4px;
          width: 32px;
          height: 32px;
          background-color: #fff;
          // filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
          border-radius: 100%;
          transform: translateY(-50%);

          ::v-deep {
            svg {
              width: 32px;
              height: 32px;
            }
          }

          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }
      }
    }

    .parpol-wrapper {
      .title {
        text-align: center;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        padding: 8px 0;

        @media only screen and (max-width: 1024px) {
          text-align: left;
          padding: 16px 0;
          margin-bottom: 0;
        }
      }

      .list-parpol {
        .parpol-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 4px;

          .parpol-image {
            border-radius: 4px 4px 0px 0px;
            height: 48px;
            width: 40px;
            padding: 4px;
            object-fit: contain;
          }

          .parpol-percentage {
            border-radius: 0px 0px 4px 4px;
            font-size: 14px;
            font-weight: 700;
            color: #fff;
            background-color: #000;
            width: 100%;
            text-align: center;
          }
        }

        .prev-slide-parpol {
          position: absolute;
          top: 60%;
          width: 32px;
          height: 32px;
          background-color: #fff;
          // filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
          border-radius: 100%;
          left: 4px;
          transform: translateY(-50%);
          z-index: 1;

          ::v-deep {
            svg {
              width: 32px;
              height: 32px;
            }
          }

          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }

        .next-slide-parpol {
          z-index: 1;
          position: absolute;
          top: 60%;
          right: 4px;
          width: 32px;
          height: 32px;
          background-color: #fff;
          // filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25));
          border-radius: 100%;
          transform: translateY(-50%);

          ::v-deep {
            svg {
              width: 32px;
              height: 32px;
            }
          }

          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }
      }

      .info {
        display: flex;
        justify-content: center;
        margin-top: 24px;

        @media only screen and (max-width: 1024px) {
          font-size: 9px;
          justify-content: flex-start;
        }
      }
    }
  }
}
</style>
