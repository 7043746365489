<template>
  <div class="container">
    <ins
      class="adsbygoogle"
      :data-adtest="$config.env !== 'production' ? 'on' : 'off'"
      style="display: inline-block"
      data-ad-client="ca-pub-7970843719346669"
      data-ad-slot="3310655748"
    ></ins>
  </div>
</template>

<script>
export default {
  mounted() {
    try {
      (adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  .adsbygoogle {
    width: 970px;
    height: 250px;
    margin: 0 auto;
    margin-top: 20px;
    @media only screen and (max-width: 1024px) {
      width: 320px;
      height: 100px;
    }
  }
}
</style>
