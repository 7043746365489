<template>
  <div class="container" :style="cssVars" v-if="isVisible">
    <div :id="id" class="horizontal-placement-gam">
      <div
        v-if="cancelIcon"
        @click="isVisible = !isVisible"
        class="close cursor-pointer left"
      ></div>
      <div id="div-gpt-ad-1678440436518-0"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "985px",
    },
    height: {
      type: String,
      default: "287px",
    },
    id: {
      type: String,
      default: "horizontal-placement",
    },
    unit: {
      type: String,
      default: "div-gpt-ad-1678440436518-0",
    },
    cancelIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssVars() {
      return {
        "--width": this.width,
        "--height": this.height,
      };
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  mounted() {
    try {
      window.googletag = window.googletag || { cmd: [] };
      googletag.cmd.push(function () {
        googletag
          .defineSlot(
            "/22849909437/homepage-horizontal-2",
            [
              [320, 100],
              [970, 250],
            ],
            "div-gpt-ad-1678440436518-0"
          )
          .addService(googletag.pubads());
        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs();
        googletag.enableServices();
        googletag.pubads().enableLazyLoad();
      });

      googletag.cmd.push(function () {
        googletag.display("div-gpt-ad-1678440436518-0");
      });
    } catch (e) {
      googletag.cmd.push(function () {
        googletag.display("div-gpt-ad-1678440436518-0");
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.horizontal-placement-gam {
  position: relative;
  width: var(--width);
  height: var(--height);
  margin: 0 auto;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 120px;
  }
  &::before {
    position: absolute;
    content: "Advertisement";
    left: 50%;
    top: 50%;
    letter-spacing: 2px;
    padding: 3px 0;
    z-index: 1;
    transform: translate3d(-50%, -50%, 0);
    color: #999;
    font-size: 12px;
  }
}
</style>
