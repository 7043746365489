<template>
  <!-- /22849909437/bottom-sticky-banner -->
  <div
    v-if="
      $route.name !== 'event-organizer-slug' && !$route.name.includes('auth')
    "
    class="container"
    :class="{ hide: !isVisible }"
  >
    <div
      id="div-gpt-ad-1681278828371-0"
      style="min-width: 320px; min-height: 50px"
    ></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    handleScroll() {
      let documentHeight = document.body.scrollHeight;
      let currentScroll = window.scrollY + window.innerHeight;
      if (currentScroll + 50 > documentHeight) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
    },
  },
  mounted() {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      var responsiveAdSlot = googletag
        .defineSlot(
          "/22849909437/bottom-sticky-banner",
          [
            [320, 50],
            [728, 90],
            [320, 100],
          ],
          "div-gpt-ad-1681278828371-0"
        )
        .addService(googletag.pubads());
      var mapping = googletag
        .sizeMapping()
        .addSize([1024, 768], [728, 90])
        .addSize(
          [200, 480],
          [
            [320, 50],
            [320, 100],
          ]
        )
        .addSize([0, 0], [])
        .build();
      responsiveAdSlot.defineSizeMapping(mapping);
      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
      googletag.enableServices();
      googletag.pubads().enableLazyLoad();
      googletag.display("div-gpt-ad-1681278828371-0");
    });
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  height: 90px;
  transition: height 0.5s;
  @media only screen and (max-width: 1024px) {
    height: 50px;
  }
  &.hide {
    height: 0;
  }
  #div-gpt-ad-1681278828371-0 {
    width: fit-content;
    margin: 0 auto;
  }
}
</style>
