<template>
  <div class="container" :style="cssVars" v-if="isVisible">
    <div :id="id" class="horizontal-placement-fixed-props">
      <div id="M857969ScriptRootC1408001" class="ads-props"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "970px",
    },
    height: {
      type: String,
      default: "250px",
    },
    id: {
      type: String,
      default: "horizontal-placement",
    },
    cancelIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssVars() {
      return {
        "--width": this.width,
        "--height": this.height,
      };
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
};
</script>

<style lang="scss" scoped>
.horizontal-placement-fixed-props {
  position: relative;
  width: var(--width);
  height: var(--height);
  margin: 0 auto;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    position: absolute;
    content: "Advertisement";
    left: 50%;
    top: 50%;
    letter-spacing: 2px;
    padding: 3px 0;
    z-index: 1;
    transform: translate3d(-50%, -50%, 0);
    color: #999;
    font-size: 12px;
  }
  // .ads-props {
  //   z-index: 2;
  //   margin-top: 32px;
  //   @media only screen and (max-width: 1024px) {
  //     margin: 0 0 32px 0;
  //   }
  // }
}
</style>
