<template>
  <div :id="id" v-if="isVisible" class="sticky-top-placement">
    <ins
      class="adsbygoogle"
      :data-adtest="$config.env !== 'production' ? 'on' : 'off'"
      style="display: inline-block; width: 160px; height: 600px"
      data-ad-client="ca-pub-7970843719346669"
      data-ad-slot="6426589155"
    ></ins>
  </div>
</template>

    <script>
export default {
  props: {
    id: {
      type: String,
      default: "sticky-top-placement",
    },
    cancelIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  mounted() {
    try {
      (adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

    <style lang="scss" scoped>
.sticky-top-placement {
  position: absolute;
  right: 0;
  height: 600px;
  width: 160px;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
}
</style>
