var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.article)?_c('Link',{staticClass:"next-article",class:{ show: _vm.isVisible },attrs:{"to":_vm.article.isText
      ? {
          name: `read-channel-slug`,
          params: {
            channel: _vm.article.channel.slug,
            slug: _vm.article.slug,
          },
          query: {
            ref: 'baca-selanjutnya',
          },
        }
      : {
          name: 'video-channel-slug',
          params: {
            channel: _vm.article.channel.slug,
            slug: _vm.article.slug,
          },
          query: {
            ref: 'baca-selanjutnya',
          },
        }}},[_c('div',{staticClass:"thumbnail"},[_c('ImageResponsive',{attrs:{"fromUrl":true,"imageUrl":_vm.article.thumbnail ? _vm.article.thumbnail.large : ''}}),_vm._v(" "),(_vm.article.duration === 'LIVE')?_c('div',{staticClass:"thumbnail__thumbnail__duration_live"},[_c('div',{staticClass:"label-live"},[_c('i',{staticClass:"fa bi-broadcast"}),_vm._v(" "),_c('span',[_vm._v("LIVE")])])]):_c('div',{staticClass:"thumbnail__thumbnail__duration",class:{ backgroundAds: _vm.article.isAdvertorial }},[(_vm.article.isAdvertorial)?_c('p',{staticClass:"advertorial"},[_vm._v("Ad")]):(_vm.article.isText)?_c('div',{staticClass:"article-reguler"},[_c('i',{staticClass:"bi bi-book-half"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("TEKS")])]):_c('p',{staticClass:"duration"},[_vm._v(_vm._s(_vm.article.duration))])])],1),_vm._v(" "),_c('div',{staticClass:"article"},[_c('div',{staticClass:"article-wrapper"},[_c('span',{staticClass:"read-next"},[_vm._v("Baca Selanjutnya")]),_vm._v(" "),_c('span',{staticClass:"read-title"},[_vm._v(_vm._s(_vm.article.title))])]),_vm._v(" "),_c('Icons',{attrs:{"name":"arrow-right","color":"#000000"}})],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }