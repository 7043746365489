<template>
  <!-- /22849909437/skyscraper-banner -->
  <div class="container_ is-desktop">
    <div
      id="div-gpt-ad-1679890261775-0"
      style="min-width: 160px; min-height: 600px"
    ></div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      var responsiveAdSlot = googletag
        .defineSlot(
          "/22849909437/skyscraper-banner",
          [160, 600],
          "div-gpt-ad-1679890261775-0"
        )
        .addService(googletag.pubads());
      var mapping = googletag
        .sizeMapping()
        .addSize([992, 0], [[160, 600]])
        .build();
      responsiveAdSlot.defineSizeMapping(mapping);
      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
      googletag.enableServices();
      googletag.pubads().enableLazyLoad();
      googletag.display("div-gpt-ad-1679890261775-0");
    });
  },
};
</script>

<style lang="scss" scoped>
.container_ {
  display: none !important;
  pointer-events: none;
  @media only screen and (min-width: 1360px) {
    display: block !important;
    pointer-events: visible;
    position: fixed;
    top: 96px;
    left: 0;
    z-index: 1000;
    padding: 0;
  }
  #div-gpt-ad-1679890261775-0 {
    width: fit-content;
  }
}
</style>
