<template>
  <div class="content-rekam-jejak">
    <div class="search-wrapper">
      <div class="header">
        <span>Powered by</span>
        <ImageResponsive imageUrl="suara-penentu/logo-rekam-jejak.png" class="logo-rekam-jejak" />
      </div>
      <h2>Kenali dan Awasi Mereka</h2>
      <div class="form-group">
        <form @submit.prevent="searchHandler">
          <input v-debounce:500ms="searchHandler" type="text" v-model="query" placeholder="Telusuri Rekam Jejak Caleg" class="form-search">
          <button type="submit" class="search">Cari</button>
        </form>
      </div>
      <div>
        <div class="icw-list-wrapper">
          <div class="line"></div>
          <span>Pilihan Teratas Versi ICW</span>
        </div>
        <div class="position-relative">
          <div class="swiper-card" v-swiper:swiperCaleg="swiperOptions">
            <div class="swiper-wrapper">
              <nuxt-link :to="`/suarapenentu/rekamjejak/${caleg.slug}`" class="swiper-slide" v-for="(caleg, index) in $store.state.suaraPenentu.caleg.items" :key="`caleg-${index}`">
                <ImageResponsiveSquare :imageUrl="caleg.photo" :fromUrl="true"
                  width="100" height="100" widthMobile="100" heightMobile="100" gravity="no" class="photo-caleg" />
                <div class="name">{{ caleg.name }}</div>
              </nuxt-link>
            </div>
          </div>
          <div class="shadow-arrow shadow-left prev-caleg">
            <div class="icon-wrapper">
              <Icons name="arrow-left" color="#fff" class="arrow prev" />
            </div>
          </div>
          <div class="shadow-arrow shadow-right next-caleg">
            <div class="icon-wrapper">
              <Icons name="arrow-right" color="#fff" class="arrow next" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="info-wrapper">
      <div>
        <h2>Kenapa sih, Rekam Jejak pejabat publik perlu diawasi?</h2>
        <span class="desc">Berdasarkan data Komisi Pemberantasan Korupsi (KPK), terdapat sedikitnya:</span>
        <div class="list-info">
          <div class="desc-wrapper">
            <span class="total">344</span>
            <span class="total-desc">Anggota legislatif terlibat dalam kasus korupsi sejak tahun 2004 hingga 2023</span>
          </div>
          <div class="desc-wrapper">
            <span class="total">176</span>
            <span class="total-desc">Kepala daerah terjerat kasus korupsi sepanjang tahun 2004 hingga 2022</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageResponsive from './ImageResponsive.vue';
import Icons from './Icons.vue';
import ImageResponsiveSquare from './ImageResponsiveSquare.vue';

export default {
  components: {
    ImageResponsiveSquare,
    ImageResponsive,
    Icons
  },
  name: 'rekamjejak',
  props: {},
  data() {
    return {
      page: 1,
      query: '',
      swiperOptions: {
        freeMode: true,
        autoHeight: true,
        calculateHeight: true,
        pagination: false,
        navigation: {
          prevEl: ".prev-caleg",
          nextEl: ".next-caleg",
        },
        breakpoints: {
          1023: {
          slidesPerGroup: 6,
            slidesPerView: 6,
            spaceBetween: 8,
          },
          320: {
            slidesPerGroup: 4,
            slidesPerView: 4,
            spaceBetween: 8,
          },
        },
      }
    }
  },
  methods: {
    searchHandler(search) {
      this.$store.dispatch("suaraPenentu/getCaleg", {
        page: 1,
        search: search
      });
    },
  },
  mounted() {
    this.$store.dispatch("suaraPenentu/getCaleg", {
      page: this.page
    });
  }
}
</script>

<style lang="scss" scoped>
.content-rekam-jejak {
  border-radius: 12px;
  background: linear-gradient(180deg, #F3F3F3 0%, rgba(243, 243, 243, 0.75) 25.52%, rgba(243, 243, 243, 0.50) 48.44%, rgba(243, 243, 243, 0.25) 71.35%, rgba(243, 243, 243, 0.00) 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
  padding: 32px;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    padding: 32px 8px;
    justify-content: center;
  }

  .search-wrapper {
    width: 50%;
    border-right: 1px solid #D9D9D9;
    padding-right: 32px;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      border-right: none;
      padding-right: 0;
      padding-bottom: 24px;
      border-bottom: 1px solid #D9D9D9;
    }

    .header {
      display: flex;
      gap: 8px;
      align-items: center;
      @media only screen and (max-width: 1024px) {
        justify-content: center;
      }

      span {
        font-size: 12px;
        color: #49898A;
        font-family: "Varela Round", sans-serif;
      }

      .logo-rekam-jejak {
        height: 24px;
        width: 104px;
      }
    }

    h2 {
      color: #49898A;
      font-family: "Varela Round", sans-serif;
      font-size: 32px;
      margin-top: 8px;
      @media only screen and (max-width: 1024px) {
        font-size: 24px;
        text-align: center;
      }
    }

    .form-group {

      .form-search {
        outline: none;
        padding: 10px 14px;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        width: 80%;
      }

      .search {
        padding: 10px 24px;
        border-radius: 8px;
        background: #135457;
        outline: none;
        border: 1px solid #135457;
        color: #FFF;
      }

      form {
        @media only screen and (max-width: 1024px) {
          display: flex;
          justify-content: space-between;
          gap: 8px;
        }
      }
    }

    .icw-list-wrapper {
      margin-top: 16px;
      display: flex;
      align-items: center;
      gap: 8px;

      .line {
        height: 16px;
        width: 4px;
        background: #49898A;
      }

      span {
        color: #49898A;
        font-family: "Varela Round", sans-serif;
      }
    }

    .swiper-card {
      .swiper-wrapper {
        height: 111px;
        padding-top: 16px;

        .swiper-slide {
          cursor: pointer;
          background: #fff;
          border-radius: 6px;
          box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.25);

          .photo-caleg {
            height: 75px;

            ::v-deep img {
              object-fit: cover;
            }
          }

          .name {
            height: 36px;
            padding: 6px;
            background: #49898A;
            font-family: "Varela Round", sans-serif;
            font-size: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            color: #fff;
          }
        }
      }
    }

    .shadow-arrow {
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 35px;
      height: 100%;
      padding: 0;
      background: rgb(0, 0, 0);
      z-index: 2;
      opacity: 1;
      transition: ease-in-out 0.5s;

      @media only screen and (max-width: 1024px) {
        display: none;
      }

      .icon-wrapper {
        width: 35px;
        height: 35px;
        background: #49898A;
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
        border-radius: 100%;

        svg {
          pointer-events: visible;
          width: 100%;
          height: 100%;
        }
      }

      &.shadow-left {
        left: 0;
        background: linear-gradient(to left,
            hsla(0, 0%, 100%, 0),
            rgba(241, 241, 241, 1));
      }

      &.shadow-right {
        right: 0;
        background: linear-gradient(to right,
            hsla(0, 0%, 100%, 0),
            rgba(241, 241, 241, 1));
      }

      &.swiper-button-disabled {
        opacity: 0;
        z-index: 0;
      }
    }

    .arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      width: auto;

      @media only screen and (max-width: 1024px) {
        display: none;
      }

      &.prev {
        left: 0;
      }

      &.next {
        right: 0;
      }

      &.hide {
        display: none;
      }
    }
  }

  .info-wrapper {
    width: 50%;
    padding-left: 32px;
    background: url(/storage/images/suara-penentu/trap.png) no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media only screen and (max-width: 1024px) {
     width: 100%;
     padding-left: 0;
     margin-top: 24px;
    }

    .menu {
      display: flex;
      gap: 24px;
      overflow: scroll;
      align-items: center;
      color: #49898A;

      .menu-item {
        cursor: pointer;

        &.active {
          text-decoration-line: underline;
          text-underline-offset: 4px;
          text-decoration-thickness: 3px;
        }
      }

    }

    .tabs-wrapper {
      .tabs-content {
        max-height: 246px;
        overflow: auto;
        margin-top: 16px;
        border-radius: 8px;
        border: 1px solid #F1F1F1;
        background: #FFF;
        padding: 16px;
        font-size: 14px;
        color: #141414;
      }
    }

    h2 {
      color: #49898A;
      font-family: "Varela Round", sans-serif;
      font-size: 24px;
      @media only screen and (max-width: 1024px) {
        font-size: 20px;
      }
    }

    .desc {
      color: #49898A;
      font-family: "Varela Round", sans-serif;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }

    .list-info {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .desc-wrapper {
        color: #49898A;
        font-family: "Varela Round", sans-serif;
        display: flex;
        align-items: center;
        gap: 16px;

        .total {
          font-size: 40px;
        }

        .total-desc {
          font-size: 16px;
          @media only screen and (max-width: 1024px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
