<template>
  <div
    class="modal modal-normal fade"
    :id="id"
    :ref="id"
    tabindex="-1"
    :aria-labelledby="id + '-label'"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.4))
    drop-shadow(0px 8px 40px rgba(0, 0, 0, 0.6));
  @media only screen and (max-width: 1024px) {
    top: 50%;
    transform: translateY(-50%);
  }
  .modal-dialog {
    justify-content: center;
  }
}
</style>

<script>
export default {
  props: {
    id: { default: "narasi-modal" },
    title: { default: null },
  },
  mounted() {
    this.$emit("registeredModal", this.getBootstrapModal(this.$refs[this.id]));
  },
};
</script>
