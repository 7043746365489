<template>
  <!-- /22849909437/playlist-page-banner/playlist-page-top-ads -->
  <div class="container">
    <div
      id="div-gpt-ad-1681292252134-0"
      style="min-width: 320px; min-height: 50px"
    ></div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      var responsiveAdSlot = googletag
        .defineSlot(
          "/22849909437/playlist-page-banner/playlist-page-top-ads",
          [
            [320, 50],
            [970, 90],
          ],
          "div-gpt-ad-1681292252134-0"
        )
        .addService(googletag.pubads());
      var mapping = googletag
        .sizeMapping()
        .addSize([1024, 768], [970, 90])
        .addSize([200, 480], [320, 50])
        .addSize([0, 0], [])
        .build();
      responsiveAdSlot.defineSizeMapping(mapping);
      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
      googletag.enableServices();
      googletag.pubads().enableLazyLoad();
      googletag.display("div-gpt-ad-1681292252134-0");
    });
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: fit-content;
  padding: 0;
  #div-gpt-ad-1681292252134-0 {
    width: fit-content;
    margin: 0 auto;
    ::v-deep iframe {
      margin-bottom: 24px !important;
    }
  }
}
</style>
