<template>
  <Link
    v-if="article"
    class="next-article"
    :class="{ show: isVisible }"
    :to="
      article.isText
        ? {
            name: `read-channel-slug`,
            params: {
              channel: article.channel.slug,
              slug: article.slug,
            },
            query: {
              ref: 'baca-selanjutnya',
            },
          }
        : {
            name: 'video-channel-slug',
            params: {
              channel: article.channel.slug,
              slug: article.slug,
            },
            query: {
              ref: 'baca-selanjutnya',
            },
          }
    "
  >
    <div class="thumbnail">
      <ImageResponsive
        :fromUrl="true"
        :imageUrl="article.thumbnail ? article.thumbnail.large : ''"
      />
      <div
        class="thumbnail__thumbnail__duration_live"
        v-if="article.duration === 'LIVE'"
      >
        <div class="label-live">
          <i class="fa bi-broadcast"></i>
          <span>LIVE</span>
        </div>
      </div>
      <div
        class="thumbnail__thumbnail__duration"
        :class="{ backgroundAds: article.isAdvertorial }"
        v-else
      >
        <p class="advertorial" v-if="article.isAdvertorial">Ad</p>
        <div class="article-reguler" v-else-if="article.isText">
          <i class="bi bi-book-half"></i>
          <p class="text">TEKS</p>
        </div>
        <p class="duration" v-else>{{ article.duration }}</p>
      </div>
    </div>
    <div class="article">
      <div class="article-wrapper">
        <span class="read-next">Baca Selanjutnya</span>
        <span class="read-title">{{ article.title }}</span>
      </div>
      <Icons name="arrow-right" color="#000000" />
    </div>
  </Link>
</template>

<script>
import Link from "@/components/Link";
import ImageResponsive from "@/components/ImageResponsive";
import Icons from "@/components/Icons";

export default {
  props: {
    article: null,
  },
  data() {
    return {
      isVisible: false,
    };
  },
  components: {
    Link,
    ImageResponsive,
    Icons,
  },
  methods: {
    handleScroll() {
      let documentHeight = document.body.scrollHeight;
      let currentScroll = window.scrollY + window.innerHeight;
      if (currentScroll > 1200 && currentScroll + 100 < documentHeight) {
        this.isVisible = true;
      } else {
        this.isVisible = false;
      }
    },
  },
  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
.next-article {
  position: sticky;
  z-index: 100;
  border-radius: 4px;
  width: 100%;
  background: #ffe500;
  box-shadow: 0px 2px 8px 0px #00000040;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0;
  bottom: 145px;
  padding: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.5s;

  @media only screen and (max-width: 1024px) {
    position: fixed;
    width: fit-content;
    margin: 0 0.75rem;
    left: 0;
  }
  &.show {
    height: 90px;
    @media only screen and (max-width: 1024px) {
      height: 75px;
    }
  }

  &.hide {
    height: 0;
  }
  .thumbnail {
    object-fit: cover;
    position: relative;
    border-radius: 3px;
    width: 20%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    padding: 12px;

    @media only screen and (max-width: 1024px) {
      display: none;
    }

    &__thumbnail {
      width: 40%;
      position: relative;

      @media only screen and (max-width: 1024px) {
        width: fit-content;
        height: 80px;
        border-radius: 3px;
      }

      &__image {
        ::v-deep img {
          border-radius: 10px;

          @media only screen and (max-width: 1024px) {
            height: 100%;
            width: auto;
            border-radius: 3px;
          }
        }
      }

      &__duration {
        background: rgba(0, 0, 0, 0.7);
        padding: 3px 6px;
        position: absolute;
        bottom: 0;
        right: 0;
        border-top-left-radius: 3px;
        border-bottom-right-radius: 3px;
        margin: 12px;

        @media only screen and (max-width: 1024px) {
          border-top-left-radius: 3px;
          border-bottom-right-radius: 3px;
        }

        .article-reguler {
          display: flex;
          align-items: center;

          .bi-book-half {
            width: 16px;
            height: auto;
            margin-right: 2px;
            color: #ffffff;
          }

          .text {
            font-size: 9px;
            font-weight: 500;
            color: #ffffff;
            margin: 0;
          }
        }

        &.backgroundAds {
          background: rgba(128, 128, 128, 0.7);
          padding: 3px 8px;
        }

        .advertorial {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          margin: 0;

          @media only screen and (max-width: 1024px) {
            font-size: 10px;
          }
        }

        .duration {
          font-size: 12px;
          font-weight: 500;
          color: #ffffff;
          margin: 0;

          @media only screen and (max-width: 1024px) {
            font-size: 10px;
          }
        }

        .label-live {
          background: #e73638;
        }
      }

      &__duration_live {
        background: #e73638;
        padding: 3px 6px;
        position: absolute;
        bottom: 0;
        right: 0;
        border-top-left-radius: 10px;
        border-bottom-right-radius: 10px;

        @media only screen and (max-width: 1024px) {
          border-top-left-radius: 6px;
          border-bottom-right-radius: 6px;
        }

        .label-live {
          display: flex;
          align-items: center;

          i {
            padding: 0 3px;
            color: #eeeeee;
            font-weight: 600;
          }

          span {
            font-size: 10px;
            color: #eeeeee;
            font-weight: 600;
          }
        }
      }
    }
  }

  .article {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    width: 80%;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }

    .article-wrapper {
      display: flex;
      flex-direction: column;
      @media only screen and (max-width: 1024px) {
        padding: 12px;
      }

      .read-next {
        font-weight: 600;
        color: #000;

        @media only screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }

      .read-title {
        font-weight: 400;
        color: #000;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        pointer-events: none;

        @media only screen and (max-width: 1024px) {
          font-size: 12px;
        }
      }
    }
    svg {
      flex: none;
      width: 30px;
      height: auto;
    }
  }
}
</style>
