<template>
  <div class="qc-wrapper">
    <div class="container">
      <div class="container-quick-count">
        <div class="info">
          <img src="/storage/images/suara-penentu/candidates/kpu.svg" alt="KPU">
          <div class="caption">
            <span>Real Count KPU</span>
            <span v-if="$store.state.suaraPenentu.recapitulations.filter((f) => f.provider === 'kpu').length > 0">Sumber: Sirekap KPU (Data masuk {{ $store.state.suaraPenentu.recapitulations.filter((f) => f.provider === 'kpu')[0].totalReceivedData}}%)</span>
          </div>
        </div>
        <div class="result">
          <div class="candidate-wrappper"
            v-for="(recapitulation, index ) in $store.state.suaraPenentu.recapitulations.filter((f) => f.provider === 'kpu')"
            :key="`candidate-kpu-${index}`">
            <div class="candidate-item" v-for="(candidate, indexItem) in recapitulation.items"
              :key="`candidate-item-${indexItem}`">
              <img :src="candidate.picture" class="candidate-item-picture" :alt="candidate.name" />
              <span class="candidate-item-percentage">{{ candidate.percentage }}%</span>
            </div>
          </div>
        </div>
        <span class="percentage-total" v-if="$store.state.suaraPenentu.recapitulations.filter((f) => f.provider === 'kpu').length > 0">Sumber: Sirekap KPU (Data masuk {{ $store.state.suaraPenentu.recapitulations.filter((f) => f.provider === 'kpu')[0].totalReceivedData}}%)</span>
        <div class="cta">
          <nuxt-link to="/suarapenentu">
            <span>Lihat selengkapnya</span>
            <img src="/storage/images/ramadan/arrow-right.svg" alt="">
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("suaraPenentu/getRecapitulations");
  },
};
</script>

<style lang="scss" scoped>
.qc-wrapper {
  padding-top: 16px;
}

.container-quick-count {
  background-color: #f3f2f6 !important;
  background: url(../static/storage/images/suara-penentu/bg_qc_new.png) no-repeat;
  background-position: bottom;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 16px;
  border: 1px solid #E0E0E0;

  @media only screen and (max-width: 1024px) {
    background: url(../static/storage/images/suara-penentu/bg_qc_new_mobile.png) no-repeat;
    flex-direction: column;
    background-size: cover;
    gap: 8px;
    border-radius: 8px;
  }

  .info {
    width: 27%;
    display: flex;
    gap: 8px;
    align-items: center;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      justify-content: center;
      height: 20px;
      margin-bottom: 8px;
    }

    img {
      height: 48px;
      @media only screen and (max-width: 1024px) {
        width: 21.68px;
      }
    }

    .caption {
      display: flex;
      flex-direction: column;

      span {
        &:nth-child(1) {
          font-size: 24px;
          font-weight: 700;
          line-height: initial;
          @media only screen and (max-width: 1024px) {
            font-size: 18px;
          }
        }

        &:nth-child(2) {
          font-size: 10px;
          font-weight: 300;
          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }
      }
    }
  }

  .result {
    width: 50%;
    border-radius: 8px;
    border: 0.75px solid #C9C9C9;
    background: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(2px);
    padding: 8px;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      padding: 0;
      border: 0;
    }

    .candidate-wrappper {
      padding: 8px;
      display: flex;
      justify-content: space-between;
      border-radius: 6px;
      gap: 12px;
      background: #FFF;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
      @media only screen and (max-width: 1024px) {
        background: transparent;
        box-shadow: unset;
        gap: 2px;
        padding: 0;
      }
      .candidate-item {
        display: flex;
        gap: 8px;
        align-items: center;
        @media only screen and (max-width: 1024px) {
          flex-direction: column;
        }
        .candidate-item-picture {
          width: 58px;
          @media only screen and (max-width: 1024px) {
            width: 99.287px;
          }
        }
        .candidate-item-percentage {
          color: #564480;
          font-size: 24px;
          font-weight: 700;
          @media only screen and (max-width: 1024px) {
            padding: 4px 15px;
            border-radius: 4px;
            background: #4C3D71;
            color: #FFF;
          }
        }
      }
    }
  }

  .percentage-total {
    display: none;
    @media only screen and (max-width: 1024px) {
      display: block;
    }
  }

  .cta {
    width: 23%;
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      flex-basis: 100%;
      align-items: center;
      color: #564480;
      &::before,
      &::after {
        content: "";
        flex-grow: 1;
        background: #564480;
        height: 1px;
      }
    }
    a {
      border-radius: 16px;
      border: 0.75px solid #564480;
      background: #FFF;
      padding: 8px 16px;
      font-size: 14px;
      color: #564480;
      display: flex;
      width: fit-content;
      align-items: center;
      @media only screen and (max-width: 1024px) {
        padding: 4px 12px;
      }
    }
  }
}
</style>
