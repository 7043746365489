<template>
  <div class="container" v-if="isVisible">
    <div :id="id" class="horizontal-slim-placement">
      <ins
        class="adsbygoogle"
        :data-adtest="$config.env !== 'production' ? 'on' : 'off'"
        style="display: inline-block; width: 970px; height: 90px"
        data-ad-client="ca-pub-7970843719346669"
        data-ad-slot="1120661275"
      ></ins>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "horizontal-slim-placement",
    },
    cancelIcon: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  mounted() {
    try {
      (adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style lang="scss" scoped>
.horizontal-slim-placement {
  width: 970px;
  height: 130px;
  margin: 0 auto;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 70px;
  }
}
</style>
