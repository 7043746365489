import { render, staticRenderFns } from "./ModalCustom.vue?vue&type=template&id=33bf7fdb&scoped=true&"
import script from "./ModalCustom.vue?vue&type=script&lang=js&"
export * from "./ModalCustom.vue?vue&type=script&lang=js&"
import style0 from "./ModalCustom.vue?vue&type=style&index=0&id=33bf7fdb&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33bf7fdb",
  null
  
)

export default component.exports