<template>
  <!-- /22849909437/homepage-banner/homepage-horizontal-ads-1 -->
  <div class="container">
    <div
      id="div-gpt-ad-1681279296364-0"
      style="min-width: 320px; min-height: 100px"
    ></div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.googletag = window.googletag || { cmd: [] };
    googletag.cmd.push(function () {
      var responsiveAdSlot = googletag
        .defineSlot(
          "/22849909437/homepage-banner/homepage-horizontal-ads-1",
          [
            [320, 100],
            [970, 250],
          ],
          "div-gpt-ad-1681279296364-0"
        )
        .addService(googletag.pubads());
      var mapping = googletag
        .sizeMapping()
        .addSize([1024, 768], [970, 250])
        .addSize([200, 480], [320, 100])
        .addSize([0, 0], [])
        .build();
      responsiveAdSlot.defineSizeMapping(mapping);
      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
      googletag.enableServices();
      googletag.pubads().enableLazyLoad();
      googletag.display("div-gpt-ad-1681279296364-0");
    });
  },
};
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: fit-content;
  padding: 0;
  #div-gpt-ad-1681279296364-0 {
    width: fit-content;
    margin: 0 auto;
    background-color: #e3e3e3;
    display: block !important;
    ::v-deep iframe {
      z-index: 2 !important;
      margin-bottom: 24px !important;
    }
    &::before {
      position: absolute;
      content: "Advertisement";
      left: 50%;
      top: 50%;
      z-index: 1;
      letter-spacing: 2px;
      padding: 3px 0;
      transform: translate3d(-50%, -50%, 0);
      color: #999;
      font-size: 12px;
    }
  }
}
</style>
