<template>
  <h2
    class="title"
    :class="[size, { 'no-border': !border }, color]"
    :style="{ 'border-color': borderColor }"
  >
    {{ title }}
  </h2>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "YOUR TITLE",
    },
    size: {
      type: String,
      default: "large",
    },
    color: {
      type: String,
      default: "",
    },
    border: {
      type: Boolean,
      default: true,
    },
    borderColor: {
      type: String,
      default: "#ffe900",
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #4a25aa;
  font-size: 20px;
  font-weight: 700;
  padding-left: 20px;
  border-left: 9px solid #ffe900;
  margin: 0;
  @media only screen and (max-width: 1024px) {
    font-size: 16px;
    padding-left: 10px;
    border-width: 4px;
  }

  @media screen and (min-device-width: 768px) and (max-device-width: 820px) {
    font-size: 14px;
  }
  &.white {
    color: #f1f1f1;
  }
  &.medium {
    font-size: 14px;
    padding-left: 12px;
    border-width: 6px;
  }
  &.xl {
    font-size: 24px;
    font-weight: 800;
    padding-left: 14px;
    border-width: 8px;
  }
  &.no-border {
    padding: 0;
    border: none;
  }
}
</style>
