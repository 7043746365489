var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-quick-count"},[_c('div',{staticClass:"quick-count"},[_c('div',{staticClass:"header-wrapper"},[_c('nuxt-link',{staticClass:"suara-penentu",attrs:{"to":"/suarapenentu"}},[_c('img',{staticClass:"logo-suara-penentu",attrs:{"src":"/storage/images/suara-penentu/logo.svg","alt":"Suara Penentu"}}),_vm._v(" "),_c('div',{staticClass:"live"},[_c('div',{staticClass:"live-badge"},[_c('span',{staticClass:"live-icon"}),_vm._v(" "),_c('span',{staticClass:"live-title"},[_vm._v("LIVE")])]),_vm._v(" "),_c('span',{staticClass:"live-quick-count-title"},[_vm._v("REKAPITULASI SUARA PEMILU 2024")])])]),_vm._v(" "),_vm._m(0)],1),_vm._v(" "),_c('div',{staticClass:"recapitulation"},[_c('div',{staticClass:"candidates"},[(_vm.showKpu)?_c('div',{staticClass:"candidate-wrapper w455"},[_c('span',{staticClass:"title"},[_vm._v("Hasil Perhitungan Resmi KPU")]),_vm._v(" "),_c('div',{staticClass:"card-wrapper"},_vm._l((_vm.$store.state.suaraPenentu.recapitulations.filter(
                (f) => f.provider === 'kpu'
              )),function(recapitulation,index){return _c('div',{key:`candidate-kpu-${index}`,staticClass:"candidate-item"},[_c('div',{staticClass:"surveor"},[(recapitulation.provider === 'kpu')?_c('span',{staticClass:"surveor-title"}):_vm._e(),_vm._v(" "),_c('img',{staticClass:"surveor-logo",attrs:{"src":recapitulation.logo,"alt":recapitulation.label}})]),_vm._v(" "),_c('div',{staticClass:"candidates-wrapper"},_vm._l((recapitulation.items),function(candidate,indexItem){return _c('div',{key:`candidate-item-${indexItem}`,staticClass:"candidate"},[_c('img',{staticClass:"candidate-item-picture",attrs:{"src":candidate.picture,"alt":candidate.name}}),_vm._v(" "),_c('span',{staticClass:"candidate-item-percentage"},[_vm._v(_vm._s(candidate.percentage)+"%")])])}),0),_vm._v(" "),_c('span',{staticClass:"candidate-item-total"},[_vm._v("Sumber:\n                "),_c('a',{attrs:{"href":"https://pemilu2024.kpu.go.id/","target":"_blank"}},[_vm._v("Sirekap KPU")]),_vm._v("\n                (Data masuk "+_vm._s(recapitulation.totalReceivedData)+"%)`")])])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"candidate-wrapper"},[_c('span',{staticClass:"title"},[_vm._v("Hasil Hitung Cepat Lembaga Survei")]),_vm._v(" "),_c('div',{staticClass:"card-wrapper"},_vm._l((_vm.$store.state.suaraPenentu.recapitulations.filter(
                (f) => f.provider !== 'kpu'
              )),function(recapitulation,index){return _c('div',{key:`candidate-non-kpu-${index}`,staticClass:"candidate-item"},[_c('div',{staticClass:"surveor"},[(recapitulation.provider === 'kpu')?_c('span',{staticClass:"surveor-title"}):_vm._e(),_vm._v(" "),_c('img',{staticClass:"surveor-logo",attrs:{"src":recapitulation.logo,"alt":recapitulation.label}})]),_vm._v(" "),_c('div',{staticClass:"candidates-wrapper"},_vm._l((recapitulation.items),function(candidate,indexItem){return _c('div',{key:`candidate-item-${indexItem}`,staticClass:"candidate"},[_c('img',{staticClass:"candidate-item-picture",attrs:{"src":candidate.picture,"alt":candidate.name}}),_vm._v(" "),_c('span',{staticClass:"candidate-item-percentage"},[_vm._v(_vm._s(candidate.percentage)+"%")])])}),0),_vm._v(" "),_c('span',{staticClass:"candidate-item-total"},[_vm._v(_vm._s(recapitulation.totalReceivedData < 1
                  ? `Data tersedia pukul 15.00 WIB`
                  : `Data masuk ${recapitulation.totalReceivedData}%`))])])}),0)])])]),_vm._v(" "),(_vm.$store.state.suaraPenentu.recapitulations.length > 0)?_c('div',{staticClass:"recapitulation-mobile"},[_c('div',{staticClass:"candidate-mobile"},_vm._l((_vm.$store.state.suaraPenentu
            .recapitulations[0].items),function(candidate,index){return _c('div',{key:`candidate-mobile-${index}`,staticClass:"recapitulation-mobile-item"},[_c('img',{attrs:{"src":candidate.picture,"alt":candidate.name}})])}),0),_vm._v(" "),_c('div',{staticClass:"candidate-percentage"},[_c('div',{staticClass:"candidate-percentage-wrapper"},[_c('span',{staticClass:"title"},[_vm._v("Hasil Perhitungan Resmi KPU")]),_vm._v(" "),_vm._l((_vm.$store.state.suaraPenentu.recapitulations.filter(
              (f) => f.provider === 'kpu'
            )),function(candidate,index){return _c('div',{key:`candidate-percentage-item-${index}`,staticClass:"candidate-percentage-item"},[_c('div',{staticClass:"candidate-percentage-title"},[_vm._v("\n              Sumber:\n              "),_c('a',{attrs:{"href":"https://pemilu2024.kpu.go.id/","target":"_blank"}},[_vm._v("Sirekap KPU")]),_vm._v("\n              (Data Masuk "+_vm._s(candidate.totalReceivedData)+"%)\n            ")]),_vm._v(" "),_c('div',{staticClass:"candidate-percentage-card"},_vm._l((candidate.items),function(candidateItem,candidateItemIndex){return _c('div',{key:`candidate-percentage-card-value-${candidateItemIndex}`,staticClass:"candidate-percentage-card-value"},[_vm._v("\n                "+_vm._s(candidateItem.percentage)+"%\n              ")])}),0)])})],2),_vm._v(" "),_c('div',{staticClass:"candidate-percentage-wrapper"},[_c('span',{staticClass:"title"},[_vm._v("Hasil Hitung Cepat Lembaga Survei")]),_vm._v(" "),_vm._l((_vm.$store.state.suaraPenentu.recapitulations.filter(
              (f) => f.provider !== 'kpu'
            )),function(candidate,index){return _c('div',{key:`candidate-percentage-item-${index}`,staticClass:"candidate-percentage-item"},[_c('div',{staticClass:"candidate-percentage-title"},[_vm._v("\n              "+_vm._s(candidate.totalReceivedData < 1
                  ? `${candidate.label} (Data tersedia pukul 15.00 WIB)`
                  : `${candidate.label} (Data Masuk ${candidate.totalReceivedData}%)`)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"candidate-percentage-card"},_vm._l((candidate.items),function(candidateItem,candidateItemIndex){return _c('div',{key:`candidate-percentage-card-value-${candidateItemIndex}`,staticClass:"candidate-percentage-card-value"},[_vm._v("\n                "+_vm._s(candidateItem.percentage)+"%\n              ")])}),0)])})],2)])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"container-wrapper"},[_c('div',{staticClass:"more-wrapper"},[(_vm.showProvider)?_c('div',{staticClass:"more-quick-count"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiper1",value:(_vm.swiperOptions),expression:"swiperOptions",arg:"mySwiper1"}],staticClass:"list-surveor",on:{"slide-change":_vm.changeProvider}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.$store.state.suaraPenentu.recapitulations.filter(
                  (f) => f.provider !== 'kpu'
                )),function(surveor,index){return _c('img',{key:`surveor-${index}`,staticClass:"list-surveor-item swiper-slide",attrs:{"src":surveor.logo,"alt":""}})}),0),_vm._v(" "),_c('div',{staticClass:"prev-slide"},[_c('Icons',{attrs:{"name":"arrow-left"}})],1),_vm._v(" "),_c('div',{staticClass:"next-slide"},[_c('Icons',{attrs:{"name":"arrow-right"}})],1)])]):_vm._e(),_vm._v(" "),(!_vm.$store.state.suaraPenentu.isLoading)?[(_vm.showTable)?[(_vm.$store.state.suaraPenentu.recapitulationsByCity)?_c('div',{staticClass:"result-table"},[_c('div',{staticClass:"result-table-candidate"},[_c('div',{staticClass:"label-title"},[_vm._v("\n                  DATA MASUK\n                  "+_vm._s(_vm.$store.state.suaraPenentu.recapitulationsByCity
                      .totalReceivedData)+"%\n                ")]),_vm._v(" "),_vm._l((_vm.$store.state.suaraPenentu
                    .recapitulationsByCity.candidate),function(candidate,index){return _c('img',{key:`candidate-city-${index}`,attrs:{"src":candidate,"alt":""}})})],2),_vm._v(" "),_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiper2",value:(_vm.swiperOptionsCity),expression:"swiperOptionsCity",arg:"mySwiper2"}],ref:"sliderCity",staticClass:"result-table-city"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.$store.state.suaraPenentu
                      .recapitulationsByCity.items),function(value,index){return _c('div',{key:`city-${index}`,staticClass:"city-item swiper-slide"},[_c('div',{staticClass:"city-wrapper"},[_c('div',{staticClass:"city-title"},[_vm._v("\n                        "+_vm._s(value.city)+" ("+_vm._s(value.totalReceivedData)+"%)\n                      ")]),_vm._v(" "),_vm._m(1,true),_vm._v(" "),_c('div',{staticClass:"tooltip-wrapper"},[_vm._v(_vm._s(value.city))])]),_vm._v(" "),_vm._l((value.percentage),function(valuePercentage,indexPercentage){return _c('div',{key:`percentage-value-${indexPercentage}`,staticClass:"value"},[_vm._v("\n                      "+_vm._s(valuePercentage)+"%\n                    ")])})],2)}),0),_vm._v(" "),_c('div',{staticClass:"prev-slide-city"},[_c('Icons',{attrs:{"name":"arrow-left"}})],1),_vm._v(" "),_c('div',{staticClass:"next-slide-city"},[_c('Icons',{attrs:{"name":"arrow-right"}})],1)])]):_vm._e()]:_vm._e(),_vm._v(" "),(_vm.showParpol)?_c('div',{staticClass:"parpol-wrapper"},[_c('h2',{staticClass:"title"},[_vm._v("\n              Rekapitulasi Data Perolehan Total Suara Nasional\n            ")]),_vm._v(" "),_c('div',{directives:[{name:"swiper",rawName:"v-swiper:mySwiper3",value:(_vm.swiperOptionsParpol),expression:"swiperOptionsParpol",arg:"mySwiper3"}],staticClass:"list-parpol"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.$store.state.suaraPenentu
                    .recapitulationsByParpol.items),function(data,index){return _c('div',{key:`parpol-${index}`,staticClass:"swiper-slide parpol-card",style:(`background: ${data.baseColor}`)},[_c('img',{staticClass:"parpol-image",attrs:{"src":`https://cdn.narasi.tv/qc/parpol/${data.parpol}.webp`,"alt":data.label}}),_vm._v(" "),_c('span',{staticClass:"parpol-percentage"},[_vm._v(_vm._s(data.percentage)+"%")])])}),0),_vm._v(" "),_c('div',{staticClass:"prev-slide-parpol"},[_c('Icons',{attrs:{"name":"arrow-left"}})],1),_vm._v(" "),_c('div',{staticClass:"next-slide-parpol"},[_c('Icons',{attrs:{"name":"arrow-right"}})],1)]),_vm._v(" "),_c('span',{staticClass:"info"},[_vm._v("Sampel masuk\n              "+_vm._s(_vm.$store.state.suaraPenentu.recapitulationsByParpol
                  .totalReceivedData)+"%; Terakhir diperbarui\n              "+_vm._s(_vm.$moment().format("DD MMM YYYY [pukul] HH:mm"))+" WIB")])]):_vm._e()]:[_vm._m(2)]],2),_vm._v(" "),(_vm.linkSelengkapnya)?_c('div',{staticClass:"devider"},[_c('nuxt-link',{staticClass:"more",attrs:{"to":_vm.linkSelengkapnya}},[_vm._v("Lihat selengkapnya\n          "),_c('Icons',{attrs:{"name":"arrow-right","color":"#564480"}})],1)],1):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sponsor"},[_c('div',{staticClass:"title"},[_vm._v("Didukung oleh:")]),_vm._v(" "),_c('div',{staticClass:"sponsor-wrapper"},[_c('img',{attrs:{"src":"/storage/images/suara-penentu/sponsor/logo-AQUA.png","alt":"AQUA"}}),_vm._v(" "),_c('img',{attrs:{"src":"/storage/images/suara-penentu/sponsor/logo-formula.png","alt":"FORMULA"}}),_vm._v(" "),_c('img',{attrs:{"src":"/storage/images/suara-penentu/sponsor/logo-strepsils.png","alt":"STREPSILS"}}),_vm._v(" "),_c('img',{attrs:{"src":"/storage/images/suara-penentu/sponsor/logo-tehgelas.png","alt":"TEH GELAS"}}),_vm._v(" "),_c('img',{attrs:{"src":"/storage/images/suara-penentu/sponsor/logo-amarbank.png","alt":"AMARBANK"}}),_vm._v(" "),_c('img',{attrs:{"src":"/storage/images/suara-penentu/sponsor/logo-kin.png","alt":"KIN"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tooptip"},[_c('span',[_vm._v("i")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading-wrapper-provider"},[_c('span',[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }