<template>
  <Link :to="to" class="title-program-wrapper">
    <ImageResponsive
      :imageUrl="image"
      :fromUrl="true"
      width="100"
      height="100"
    />
    <h1 class="title-program">{{ title }}</h1>
  </Link>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "YOUR TITLE",
    },
    image: {
      type: String,
      default: "https://narasi.tv/storage/images/dummy.png",
    },
    to: {
      type: [Object, String],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.title-program-wrapper {
  display: flex;
  align-items: center;
  .image {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 25px;
    border: 1px solid lightgray;
    @media only screen and (max-width: 1024px) {
      width: 40px;
      height: 40px;
      margin-right: 15px;
    }
  }
  .title-program {
    color: #252525;
    font-size: 24px;
    font-weight: 700;
    margin: 0;
    @media only screen and (max-width: 1024px) {
      font-size: 16px;
      border-width: 4px;
    }
  }
}
</style>
