<template>
  <div class="container" :style="cssVars" v-if="isVisible">
    <div :id="id" class="horizontal-placement">
      <ins
        class="adsbygoogle"
        :data-adtest="$config.env !== 'production' ? 'on' : 'off'"
        style="display: inline-block; width: 728px; height: 250px"
        data-ad-client="ca-pub-7970843719346669"
        data-ad-slot="4812494278"
      ></ins>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "745px",
    },
    height: {
      type: String,
      default: "287px",
    },
    id: {
      type: String,
      default: "horizontal-placement",
    },
    cancelIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssVars() {
      return {
        "--width": this.width,
        "--height": this.height,
      };
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  mounted() {
    try {
      (adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style lang="scss" scoped>
.horizontal-placement {
  width: var(--width);
  height: var(--height);
  margin: 0 auto;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 120px;
  }
}
</style>
