<template>
  <div class="container" :style="cssVars" v-if="isVisible">
    <div :id="id" class="horizontal-placement-fixed">
      <ins
        class="adsbygoogle"
        :data-adtest="$config.env !== 'production' ? 'on' : 'off'"
        style="display: inline-block; width: 300px; height: 250px"
        data-ad-client="ca-pub-7970843719346669"
        data-ad-slot="7001304221"
      ></ins>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "970px",
    },
    height: {
      type: String,
      default: "250px",
    },
    id: {
      type: String,
      default: "horizontal-placement",
    },
    cancelIcon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssVars() {
      return {
        "--width": this.width,
        "--height": this.height ? this.height : "300px",
      };
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  mounted() {
    try {
      (adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.log(e);
    }
  },
};
</script>

<style lang="scss" scoped>
.horizontal-placement-fixed {
  position: relative;
  width: var(--width);
  height: var(--height);
  margin: 0 auto;
  background-color: #e3e3e3;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    position: absolute;
    content: "Advertisement";
    left: 50%;
    top: 50%;
    letter-spacing: 2px;
    padding: 3px 0;
    z-index: 1;
    transform: translate3d(-50%, -50%, 0);
    color: #999;
    font-size: 12px;
  }
}
</style>
